import React, { useRef } from 'react';
import { uploadAndProcessImage } from '../utils/api';

function ImageUploader({ onUploadStart, onImageProcessed }) {
  const fileInputRef = useRef(null);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      onUploadStart();
      try {
        const result = await uploadAndProcessImage(file);
        onImageProcessed(result);
      } catch (error) {
        console.error('图片处理失败:', error);
        alert('图片处理失败: ' + (error.message || '未知错误'));
      }
    }
  };

  return (
    <div className="image-uploader">
      <input
        type="file"
        accept="image/*"
        onChange={handleUpload}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <button onClick={() => fileInputRef.current.click()}>上传图片</button>
    </div>
  );
}

export default ImageUploader;
