const API_BASE_URL = 'http://localhost:5000'; // 确保这里的端口号与后端服务器匹配

export async function uploadAndProcessImage(file) {
  const formData = new FormData();
  formData.append('image', file);

  const response = await fetch(`${API_BASE_URL}/process_image`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('图片上传或处理失败');
  }

  const result = await response.json();
  return {
    original: `${API_BASE_URL}${result.original}`,
    processed: `${API_BASE_URL}${result.processed}`
  };
}
