import React, { useState, useRef } from 'react';
import ImageUploader from './components/ImageUploader';
import ImageComparison from './components/ImageComparison';
import LoadingSpinner from './components/LoadingSpinner';
import { uploadAndProcessImage } from './utils/api';
import './styles/main.css';

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [processedImage, setProcessedImage] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageProcessed = (result) => {
    setProcessedImage(result);
    setIsLoading(false);
  };

  const handleUpload = async (file) => {
    if (file) {
      setIsLoading(true);
      try {
        const result = await uploadAndProcessImage(file);
        handleImageProcessed(result);
      } catch (error) {
        console.error('图片处理失败:', error);
        alert('图片处理失败: ' + (error.message || '未知错误'));
        setIsLoading(false);
      }
    }
  };

  const handleReupload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error('文件输入元素未找到');
    }
  };

  const handleDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'processed_image.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('下载图片失败:', error);
      alert('下载图片失败: ' + (error.message || '未知错误'));
    }
  };

  return (
    <div className="app">
      <h1>一键抠图</h1>
      <p className="subtitle">无需担心复杂的抠图步骤，一键操作，让创作变得简单、高效</p>
      
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleUpload(e.target.files[0])}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />

      {!processedImage && !isLoading && (
        <>
          <div className="hero-image">
            <img src={`${process.env.PUBLIC_URL}/images/background.jpg`} alt="抠图示例" />
          </div>
          <ImageUploader
            onUploadStart={() => setIsLoading(true)}
            onImageProcessed={handleImageProcessed}
          />
        </>
      )}
      {isLoading && <LoadingSpinner />}
      {processedImage && (
        <>
          <ImageComparison
            originalImage={processedImage.original}
            processedImage={processedImage.processed}
          />
          <div className="button-group">
            <button onClick={handleReupload}>重新上传</button>
            <button onClick={() => handleDownload(processedImage.processed)}>下载图片</button>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
